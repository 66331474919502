import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import isNull from 'lodash/isNull';
import BackgroundImage from 'gatsby-background-image';
import clsx from 'clsx';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import uniqueId from 'lodash/uniqueId';
import { styleConfig } from '../../config/config';

import RouteLink from './RouteLink';
import Section from './Common/Section';
import Title from './Common/Title';

const ContentModuleMultiImageBlocks: React.FunctionComponent<ContentModuleMultiImageBlocksProps> = (
  props
) => {
  const { subtitle, headline, rows } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      position: 'relative',
    },
    arrow: {
      verticalAlign: 'middle',
      display: 'inline-flex',
      width: 42,
      height: 42,
      transition: '0.3s ease-in-out all',
      opacity: 0,
      marginLeft: 10,

      [theme.breakpoints.down('lg')]: {
        opacity: 1,
      },
    },
    imageRoot: {
      height: 335,

      [theme.breakpoints.up('lg')]: {
        height: 335,
      },
    },
    image: {
      transition: 'all .8s cubic-bezier(.76,.53,.22,.86)',
      zIndex: 0,
      transform: 'scale(1)',

      [theme.breakpoints.down('md')]: {
        height: 335,
      },

      [theme.breakpoints.up('xl')]: {
        height: '50rem',
      },
    },
    content: {
      position: 'absolute',
      top: 0,
      overflow: 'hidden',
      zIndex: 555,
      display: 'block',
      padding: '3rem 2rem',
      height: '100%',
      width: '100%',

      [theme.breakpoints.down('lg')]: {
        padding: '2rem',
        width: '100%',
      },

      [theme.breakpoints.up('xl')]: {
        padding: '4rem',
        width: '100%',
      },
    },
    model: {
      position: 'relative',
      overflow: 'hidden',
      transition: '0.3s ease-in-out all',
      background: 'transparent',
      willChange: 'scale, transform',

      '&:before': {
        transition: '0.3s ease-in-out all',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.30)',
        content: "''",
        zIndex: 1,
      },

      '&:hover': {
        '& $arrow': {
          transform: 'translate3d(15px, 0, 0)',
          willChange: 'transform',
          opacity: 1,
        },

        '&:before': {
          background: 'rgba(0, 0, 0, 0.50)',
        },

        '& $image': {
          transform: 'scale(1.1)',
          willChange: 'scale',
        },

        '& $subtitle': {
          [theme.breakpoints.up('lg')]: {
            bottom: '4rem',
          },
        },
      },
    },
    sectionTitle: {
      position: 'relative',
      display: 'block',
      padding: '6rem 0',
      textAlign: 'center',

      [theme.breakpoints.down('sm')]: {
        padding: '6rem 1rem',
      },
    },
    title: {
      color: styleConfig.color.text.white,
      display: 'block',
      margin: '0',
      fontFamily: 'Bai Jamjuree',
      fontSize: '3.5rem!important',
      fontWeight: 600,

      [theme.breakpoints.down('md')]: {
        fontSize: '2.4rem!important',
      },
    },
    subtitle: {
      fontSize: 20,
      fontWeight: 600,
      color: styleConfig.color.text.gold,

      '&:hover': {
        '& a': {
          '&:hover': {
            textDecoration: 'underline',
          },
          '.arrow': {
            opacity: 1,
          },
        },
      },
    },
    double: {
      height: 503,

      [theme.breakpoints.up('lg')]: {
        height: 503,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Section nopad>
      <div className={classes.root}>
        <React.Fragment>
          {!isNull(headline) && !isNull(subtitle) && headline && (
            <Grid container justifyContent="center">
              <Grid item sm={12} md={10}>
                <Box className={classes.sectionTitle}>
                  <Title
                    component="h2"
                    alignment="center"
                    isSubtitle
                    subtitleText={subtitle}
                    subtitleType="subtitle1"
                    titleCaps
                    titleColor={styleConfig.color.text.darkgrey}
                    titleHeavy
                    titleSize="h2"
                    titleText={headline}
                  />
                </Box>
              </Grid>
            </Grid>
          )}

          <Grid container>
            {rows &&
              rows.map((row) => {
                return (
                  row &&
                  row.mib_columns.map((col) => {
                    const count = (12 / row.mib_columns.length) as 4 | 6 | 12;
                    const isDouble = count === 6;

                    const image = getImage(col.mib_image.localFile);
                    const bgImage = convertToBgImage(image);

                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={count}
                        lg={count}
                        xl={count}
                        key={uniqueId()}
                        style={{ background: styleConfig.color.bg.darkgrey }}
                      >
                        {col && col.mib_link && col.mib_link.url && (
                          <RouteLink href={col && col.mib_link && col.mib_link.url}>
                            <div className={classes.model}>
                              {col.mib_image && col.mib_image.localFile && (
                                <div
                                  className={clsx([classes.imageRoot, isDouble && classes.double])}
                                >
                                  <BackgroundImage
                                    fadeIn={false}
                                    style={{ height: '100%' }}
                                    className={clsx([classes.image, isDouble && classes.double])}
                                    alt={col.mib_image.alt_text}
                                    {...bgImage}
                                  />
                                </div>
                              )}

                              <div className={clsx([classes.content, isDouble && classes.double])}>
                                {col && (
                                  <React.Fragment>
                                    <h3 className={clsx([classes.title])}>
                                      {col.mib_title}

                                      {col.mib_link && col.mib_link.url && (
                                        <ArrowRightAltIcon
                                          color="primary"
                                          className={classes.arrow}
                                        />
                                      )}
                                    </h3>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </RouteLink>
                        )}
                      </Grid>
                    );
                  })
                );
              })}
          </Grid>
        </React.Fragment>
      </div>
    </Section>
  );
};

type Column = {
  mib_title: string;
  mib_image: {
    alt_text: string;
    localFile: any;
  };
  mib_link: {
    url: string;
  };
};

type Row = {
  mib_columns: Array<Column>;
};

interface ContentModuleMultiImageBlocksProps {
  headline: string;
  subtitle: string;
  rows: Array<Row>;
}

export default ContentModuleMultiImageBlocks;
